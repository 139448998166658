import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import ComponentPreview from '../../../components/ComponentPreview';
import { SprkDivider } from '@sparkdesignsystem/spark-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Award`}</h1>
    <p>{`A layout for showcasing awards and disclaimers.`}</p>
    <ComponentPreview componentName="award--default-story" hasReact hasAngular hasHTML minHeight="26rem" maxWidth="100%" titleAttr="Award Example" mdxType="ComponentPreview" />
    <h3>{`Usage`}</h3>
    <p>{`Display company accolades and disclaimers on a page or in a `}<a parentName="p" {...{
        "href": "/using-spark/components/footer"
      }}>{`Footer`}</a>{`.`}</p>
    <h3>{`Guidelines`}</h3>
    <ul>
      <li parentName="ul">{`Heading text can wrap.`}</li>
      <li parentName="ul">{`Award images will stack by default and become side by side on larger viewports.`}</li>
      <li parentName="ul">{`Award images may be clickable links.`}</li>
      <li parentName="ul">{`Default to `}<a parentName="li" {...{
          "href": "/using-spark/components/toggle"
        }}>{`Toggle`}</a>{` usage guidelines for the disclaimer.`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h2>{`Anatomy`}</h2>
    <ul>
      <li parentName="ul">{`Award must have a headline.`}</li>
      <li parentName="ul">{`Award must have 1–2 images.`}</li>
      <li parentName="ul">{`Award must have a disclaimer in a Toggle.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      